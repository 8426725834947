import { defineAsyncComponent, createApp } from 'vue';

const VStatementServiceListView = defineAsyncComponent(() => import('@app_mektep/views/VStatementServiceListView/VStatementServiceListView.vue'));

export default function (mixBoot) {
  if (document.getElementById('v-statement-service-list-view')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VStatementServiceListView', VStatementServiceListView);
    VueApp.mount('#v-statement-service-list-view');
    window.vStatementServiceListView = VueApp;
  }
}
