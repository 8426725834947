import { defineAsyncComponent, createApp } from 'vue';

const VStatementServiceDocs = defineAsyncComponent(() => import('@app_mektep/views/VStatmentServiceDocs/VStatementServiceDocs.vue'));

export default function (mixBoot) {
  if (document.getElementById('v-statement-service-docs-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VStatementServiceDocs', VStatementServiceDocs);
    VueApp.mount('#v-statement-service-docs-app');
    window.VStatementServiceDocsApp = VueApp;
  }
}
