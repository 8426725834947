import { defineAsyncComponent, createApp } from 'vue';

const VTable = defineAsyncComponent(() => import('@vjs/components/VTable/VTable.vue'));

export default function (mixBoot) {
  if (document.getElementById('v-table-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VTable', VTable);
    VueApp.mount('#v-table-app');
    window.vTable = VueApp;
  }
}
