import vAdaptiveMenuApp from './v-adaptive-menu-app';
import vAppClubsList from './v-app-clubs-list';
import vApplicationTables from './v-application-tables';
import vBooksAdditionRfidApp from './v-books-addition-rfid-app';
import vContingentApp from './v-contingent-app';
import vFeedbackApp from './v-feedback-app';
import vMenuApp from './v-menu-app';
import vNpa from './v-npa';
import vOrganizationsListView from './v-organizations-list-view';
import vResetPassApp from './v-reset-pass-app';
import vServiceLimitEditView from './v-service-limit-edit-view';
import vStatementServiceDocs from './v-statement-service-docs';
import vStatementServiceListView from './v-statement-service-list-view';
import vTableApp from './v-table-app';

export default function (mixBoot) {
  vAdaptiveMenuApp(mixBoot);
  vAppClubsList(mixBoot);
  vApplicationTables(mixBoot);
  vBooksAdditionRfidApp(mixBoot);
  vContingentApp(mixBoot);
  vFeedbackApp(mixBoot);
  vMenuApp(mixBoot);
  vNpa(mixBoot);
  vOrganizationsListView(mixBoot);
  vResetPassApp(mixBoot);
  vServiceLimitEditView(mixBoot);
  vStatementServiceDocs(mixBoot);
  vStatementServiceListView(mixBoot);
  vTableApp(mixBoot);
}
