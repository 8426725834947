import { defineAsyncComponent, createApp } from 'vue';

const VApplicationTable = defineAsyncComponent(() => import('@app_mektep/views/VApplicationTable'));
const VApplicationTableEgov = defineAsyncComponent(() => import('@app_mektep/views/VApplicationTableEgov'));

export default function (mixBoot) {
  if (document.getElementById('v-application-tables')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VApplicationTable', VApplicationTable);
    VueApp.component('VApplicationTableEgov', VApplicationTableEgov);
    VueApp.mount('#v-application-tables');
    window.vApplicationTable = VueApp;
  }
}
