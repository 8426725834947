import { defineAsyncComponent } from 'vue';

const CreateBookCopiesModal = defineAsyncComponent(() => import('./CreateBookCopies'));
const UserRejectStatement = defineAsyncComponent(() => import('./User/RejectStatement'));
const DeleteContingentModal = defineAsyncComponent(() => import('./DeleteContingentModal'));
const DeletePersonnelModal = defineAsyncComponent(() => import('./DeletePersonnelModal'));
const ScheduleExaminationModal = defineAsyncComponent(() => import('./ScheduleExamination'));
const Confirm = defineAsyncComponent(() => import('./Confirm'));
const ConfirmWithMessage = defineAsyncComponent(() => import('./ConfirmWithMessage'));
const ElectronicContractConfirm = defineAsyncComponent(() => import('./ElectronicContract/ElectronicContractConfirm'));
const FindBook = defineAsyncComponent(() => import('./IssuanceOfBooksModals/FindBookModal'));
const TableModal = defineAsyncComponent(() => import('./IssuanceOfBooksModals/TableModal'));
const ConfirmGiveBook = defineAsyncComponent(() => import('./IssuanceOfBooksModals/ConfirmGiveBookModal'));
const ChooseLanguage = defineAsyncComponent(() => import('./ElectronicContract/ChooseLanguage'));
const CreateLibraryActWriteOff = defineAsyncComponent(() => import('./CreateLibraryActWriteOff'));
const CreateLibraryActTransfer = defineAsyncComponent(() => import('./CreateLibraryActTransfer'));
const StatementSubscribe = defineAsyncComponent(() => import('./StatementSubscribe'));
const StatementReject = defineAsyncComponent(() => import('./StatementReject'));
const SignNcalayer = defineAsyncComponent(() => import('./SignNcalayer'));
const SelectUserFile = defineAsyncComponent(() => import('./SelectUserFiles'));
const ConfirmWithFieldsModal = defineAsyncComponent(() => import('./ConfirmWithFields'));
const InfoTableWithActions = defineAsyncComponent(() => import('./InfoTableWithActions'));
const PmpkConfirm = defineAsyncComponent(() => import('./PmpkConfirm'));

export default function (Vue) {
  Vue.component('VModelUserRejectStatement', UserRejectStatement);
  Vue.component('VModalDeleteContingent', DeleteContingentModal);
  Vue.component('VModalDeletePersonnel', DeletePersonnelModal);
  Vue.component('VModalScheduleExamination', ScheduleExaminationModal);
  Vue.component('VModalConfirm', Confirm);
  Vue.component('VModalElectronicContractConfirm', ElectronicContractConfirm);
  Vue.component('VModalFindBook', FindBook);
  Vue.component('VModalTable', TableModal);
  Vue.component('VModalConfirmGiveBook', ConfirmGiveBook);
  Vue.component('VModalChooseLanguage', ChooseLanguage);
  Vue.component('VModalCreateLibraryActWriteOff', CreateLibraryActWriteOff);
  Vue.component('VModalCreateLibraryActTransfer', CreateLibraryActTransfer);
  Vue.component('VModalCreateBookCopies', CreateBookCopiesModal);
  Vue.component('VModalConfirmWithMessage', ConfirmWithMessage);
  Vue.component('VModalStatementSubscribe', StatementSubscribe);
  Vue.component('VModalStatementReject', StatementReject);
  Vue.component('VModalSignNcalayer', SignNcalayer);
  Vue.component('VModalSelectUserFiles', SelectUserFile);
  Vue.component('VConfirmWithFields', ConfirmWithFieldsModal);
  Vue.component('VModalInfoTableWithActions', InfoTableWithActions);
  Vue.component('VModalPmpkConfirm', PmpkConfirm);
}
