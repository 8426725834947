import { defineAsyncComponent } from 'vue';

const Pagination = defineAsyncComponent(() => import('@app_mektep/components/Legacy/components/pagination/main.vue'));
const Regions = defineAsyncComponent(() => import('@app_mektep/components/Legacy/components/regions/regions.vue'));
const SalaryCalc = defineAsyncComponent(() => import('./components/SalaryCalc/SalaryCalc.vue'));
const Sel2 = defineAsyncComponent(() => import('./components/Sel2/Sel2.vue'));
const ApplyStatementFormComponent = defineAsyncComponent(() => import('./components/AdditionalEducation/ApplyStatementFormComponent.vue'));
const Stepper = defineAsyncComponent(() => import('./components/Stepper/Stepper.vue'));
const StatementParentForm = defineAsyncComponent(() => import('./components/StatementParentForm/StatementParentForm.vue'));
const StatementChildForm = defineAsyncComponent(() => import('./components/StatementChildForm/StatementChildForm.vue'));
const StatementDocsForm = defineAsyncComponent(() => import('./components/StatementDocsForm/StatementDocsForm.vue'));
const ServiceAdoptionInfoStep = defineAsyncComponent(() => import('./components/StatementServiceSteps/ServiceAdoptionInfoStep.vue'));
const StatementServiceSteps = defineAsyncComponent(() => import('./components/StatementServiceSteps/StatementServiceSteps.vue'));

const TrainingScheduleForm = defineAsyncComponent(() => import('./components/TrainingSchedule/Form'));
const TrainingScheduleShow = defineAsyncComponent(() => import('./components/TrainingSchedule/Show'));
const ManageSubclass = defineAsyncComponent(() => import('./components/ManageSubclass'));

const SelectOrganizationForm = defineAsyncComponent(() => import('./components/Views/SelectOrganization/Form'));
const SelectRoleForm = defineAsyncComponent(() => import('./components/Views/SelectRole/Form'));
const LoadingForm = defineAsyncComponent(() => import('./components/Views/Loading/Form'));

export default function (Vue) {
  Vue.component('SalaryCalc', SalaryCalc);
  Vue.component('Sel2', Sel2);
  Vue.component('ApplyStatementFormComponent', ApplyStatementFormComponent);
  Vue.component('VueTrainingScheduleForm', TrainingScheduleForm);
  Vue.component('VueTrainingScheduleShow', TrainingScheduleShow);
  Vue.component('VueManagesubclass', ManageSubclass);
  Vue.component('VueLoadingForm', LoadingForm);
  Vue.component('VueSelectOrganizationForm', SelectOrganizationForm);
  Vue.component('VueSelectRoleForm', SelectRoleForm);

  Vue.component('Stepper', Stepper);
  Vue.component('StatementParentForm', StatementParentForm);
  Vue.component('StatementChildForm', StatementChildForm);
  Vue.component('StatementDocsForm', StatementDocsForm);
  Vue.component('StatementServiceSteps', StatementServiceSteps);
  Vue.component('ServiceAdoptionInfoStep', ServiceAdoptionInfoStep);

  Vue.component('Pagination', Pagination);
  Vue.component('Regions', Regions);
}
