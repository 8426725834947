import { defineAsyncComponent, createApp } from 'vue';

const VFeedbackForm = defineAsyncComponent(() => import('@vjs/views/VFeedbackForm'));

export default function (mixBoot) {
  if (document.getElementById('v-feedback-app')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('VFeedbackForm', VFeedbackForm);
    VueApp.mount('#v-feedback-app');
    window.vFeedbackApp = VueApp;
  }
}
