import { defineAsyncComponent, createApp } from 'vue';

const NpaList = defineAsyncComponent(() => import('@vjs/mektep/views/Npa/NpaList.vue'));

export default function (mixBoot) {
  if (document.getElementById('v-npa')) {
    const VueApp = createApp({});
    mixBoot(VueApp, false);
    VueApp.component('NpaList', NpaList);
    VueApp.mount('#v-npa');
    window.vNpa = VueApp;
  }
}
