import { defineAsyncComponent } from 'vue';

const VLoginForm = defineAsyncComponent(() => import('@common/views/VLoginForm'));
const RegistrationPhoneForm = defineAsyncComponent(() => import('@common/views/FormRegistrationSms'));
const VOrganizationProfile = defineAsyncComponent(() => import('@common/views/VOrganizationProfile'));
const VOrganizationEditForm = defineAsyncComponent(() => import('@common/views/VOrganizationEditForm'));
const VOrganizationInfo = defineAsyncComponent(() => import('@common/views/VOrganizationInfo'));
const NewTableWithFilters = defineAsyncComponent(() => import('@common/views/NewTableWithFilters'));
const VSupportTeamButton = defineAsyncComponent(() => import('@vjs/balabaqsha/views/VSupportTeamButton'));
const VProfileForm = defineAsyncComponent(() => import('./Profile/Form'));
const VStatementServiceForm = defineAsyncComponent(() => import('./VStatementServiceForm'));
const VBinomConfirmationIinForm = defineAsyncComponent(() => import('./Binom/VConfirmationIinForm'));
const VContingentForm = defineAsyncComponent(() => import('./Contingent/Form'));
const VBookCreateForm = defineAsyncComponent(() => import('./VBookCreate/Form'));
const VElectronicContractCreate = defineAsyncComponent(() => import('./VElectronicContractCreate/Form'));
const VSchoolServiceAddressForm = defineAsyncComponent(() => import('./VSchoolServiceAddressForm/Form'));
const VLibraryStatementBookForm = defineAsyncComponent(() => import('./VLibraryStatementBookForm'));
const VLibraryBriefcaseForm = defineAsyncComponent(() => import('./VLibraryBriefcaseForm'));
const VLibraryBriefcaseContentForm = defineAsyncComponent(() => import('./VLibraryBriefcaseContentForm'));
const VElectronicContractConclude = defineAsyncComponent(() => import('./VElectronicContractConclude'));
const VElectronicContractShow = defineAsyncComponent(() => import('./VElectronicContractShow'));
const ParentStatementShow = defineAsyncComponent(() => import('./ParentStatementShow'));
const SubjectQuestions = defineAsyncComponent(() => import('./SubjectQuestions'));
const VMainPage = defineAsyncComponent(() => import('./VMainPage'));
const VPassportPageView = defineAsyncComponent(() => import('./VPassportPageView'));
const VBooksAdditionAlt = defineAsyncComponent(() => import('./VBooksAdditionAlt'));
const VLibraryStatementBookTable = defineAsyncComponent(() => import('./VLibraryStatementBookTable'));
const VInProgressAltBooksList = defineAsyncComponent(() => import('./VInProgressAltBooksList'));
const VLibraryActArrivalShow = defineAsyncComponent(() => import('./VLibraryActArrivalShow'));
const VLibraryActWriteOffShow = defineAsyncComponent(() => import('./VLibraryActWriteOffShow'));
const VLibraryActTransferShow = defineAsyncComponent(() => import('./VLibraryActTransferShow'));
const VMonitoringList = defineAsyncComponent(() => import('./VMonitoringList'));
const VLibraryDebitCreditMonitoring = defineAsyncComponent(() => import('./Monitorings/VLibraryDebitCreditMonitoring'));
const VMonitoringTree = defineAsyncComponent(() => import('./Monitorings/VMonitoringTree'));
const VLibraryStatementShow = defineAsyncComponent(() => import('./VLibraryStatementShow'));
const VLibraryBookPlansForm = defineAsyncComponent(() => import('./VLibraryBookPlansForm'));
const VRegistrationCheck = defineAsyncComponent(() => import('./VRegistrationCheck'));
const VOrganizationProfileAdditional = defineAsyncComponent(() => import('./VOrganizationProfileAdditional'));
const VSchoolCertificateStatementForm = defineAsyncComponent(() => import('./VSchoolCertificateStatementForm'));
const VPersonnelCardForm = defineAsyncComponent(() => import('./VPersonnelCardForm'));
const VLaborReportForm = defineAsyncComponent(() => import('./VLaborReportForm'));
const ProfileAvatar = defineAsyncComponent(() => import('./ProfileAvatar'));
const VMeetingsCalendar = defineAsyncComponent(() => import('./VMeetingsCalendar'));
const VCustomForm = defineAsyncComponent(() => import('./VCustomForm'));
const VCustomFormNew = defineAsyncComponent(() => import('./VCustomFormNew'));
const VOrgMTB = defineAsyncComponent(() => import('./VOrgMTB'));
const VCorruptionMap = defineAsyncComponent(() => import('./VCorruptionMap'));
const VEditable = defineAsyncComponent(() => import('./EditableTable'));

export default function (Vue) {
  Vue.component('VueRegistrationPhoneForm', RegistrationPhoneForm);
  Vue.component('VueBinomConfirmationIinForm', VBinomConfirmationIinForm);
  Vue.component('VLoginForm', VLoginForm);

  Vue.component('VOrganizationProfile', VOrganizationProfile);
  Vue.component('VOrganizationInfo', VOrganizationInfo);
  Vue.component('VOrganizationEditForm', VOrganizationEditForm);
  Vue.component('VStatementServiceForm', VStatementServiceForm);
  Vue.component('VProfileForm', VProfileForm);
  Vue.component('VueNewTableWithFilters', NewTableWithFilters);
  Vue.component('VContingentForm', VContingentForm);
  Vue.component('VBookCreateForm', VBookCreateForm);
  Vue.component('VElectronicContractCreate', VElectronicContractCreate);
  Vue.component('VSchoolServiceAddressForm', VSchoolServiceAddressForm);
  Vue.component('VLibraryStatementBookForm', VLibraryStatementBookForm);
  Vue.component('VLibraryBriefcaseForm', VLibraryBriefcaseForm);
  Vue.component('VLibraryBriefcaseContentForm', VLibraryBriefcaseContentForm);
  Vue.component('VElectronicContractConclude', VElectronicContractConclude);
  Vue.component('VElectronicContractShow', VElectronicContractShow);
  Vue.component('VParentStatementShow', ParentStatementShow);
  Vue.component('VSubjectQuestions', SubjectQuestions);
  Vue.component('VMainPage', VMainPage);
  Vue.component('VSupportTeamButton', VSupportTeamButton);
  Vue.component('VPassportPageView', VPassportPageView);
  Vue.component('VBooksAdditionAlt', VBooksAdditionAlt);
  Vue.component('VLibraryStatementBookTable', VLibraryStatementBookTable);
  Vue.component('VInProgressAltBooksList', VInProgressAltBooksList);
  Vue.component('VLibraryActArrivalShow', VLibraryActArrivalShow);
  Vue.component('VLibraryActWriteOffShow', VLibraryActWriteOffShow);
  Vue.component('VLibraryActTransferShow', VLibraryActTransferShow);
  Vue.component('VMonitoringList', VMonitoringList);
  Vue.component('VLibraryDebitCreditMonitoring', VLibraryDebitCreditMonitoring);
  Vue.component('VMonitoringTree', VMonitoringTree);
  Vue.component('VLibraryStatementShow', VLibraryStatementShow);
  Vue.component('VRegistrationCheck', VRegistrationCheck);
  Vue.component('VLibraryBookPlansForm', VLibraryBookPlansForm);
  Vue.component('VOrganizationProfileAdditional', VOrganizationProfileAdditional);
  Vue.component('VSchoolCertificateStatementForm', VSchoolCertificateStatementForm);
  Vue.component('VPersonnelCardForm', VPersonnelCardForm);
  Vue.component('VLaborReportForm', VLaborReportForm);
  Vue.component('VueProfileAvatar', ProfileAvatar);
  Vue.component('VMeetingsCalendar', VMeetingsCalendar);
  Vue.component('VCustomForm', VCustomForm);
  Vue.component('VCustomFormNew', VCustomFormNew);
  Vue.component('VOrgMtb', VOrgMTB);
  Vue.component('VCorruptionMap', VCorruptionMap);
  Vue.component('VEditable', VEditable);
}
